<header class="masthead container">
      <nav class="navbar navbar-expand-lg navbar-light">
    <!-- <a class="navbar-brand" href="#">Navbar</a> -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto"></ul>
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#prodects">Products</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#services">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="events">Events</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contact Us</a>
        </li>
      </ul>
    </div>
  </nav>
    <div class="container h-50">
      <div class="row h-100 align-items-center">
        <div class="col-12">
          <!-- <h1 class="font-weight-light">Vertically Centered Masthead Content</h1>
          <p class="lead">A great starter layout for a landing page</p> -->
          <div class="row">
            <img src="assets/img/brand/logo.png" class="rounded float-left" alt="hayes.com">
        </div>
        <div class="row poppins">
            <p>Hayes chemical and industry Co., Ltd <br>
                provides its customers with the highest quality of products and services .</p>
        </div>
        </div>
      </div>
    </div>
  </header>