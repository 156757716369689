<!-- <app-header></app-header> -->
<div class="jumbotron body container" style="background-color: #fff; height: calc(95vh);">
    <div class="row desc font-promt">
        <h6>บริษัท เฮย์ส เคมิคอล และ อุตสาหกรรม จำกัด</h6>
        <p>เป็นผู้จัดจำหน่ายสินค้าและให้บริการ  สารเคมีสำหรับอุตสาหกรรม สารเคมีบำบัดน้ำเสีย สารเคมีห้องแลป จำหน่าย อุปกรณ์วิทยาศาสตร์ อุปกรณ์ความปลอดภัย  เครื่องมือ
            เครื่องจักร สำหรับโรงงานอุตสาหกรรม รวมถึงโปรแกรมสำหรับการจัดการข้อมูลขององค์กร และรวมถึง บริการงานก่อสร้างต่อเติม งานสั่งผลิตตามสเป็ค งานเคลื่อนย้าย 
            และ ติดตั้งเครื่องจักร บริการ งานทำความสะอาด ทาสีโครงสร้างอาคาร ขนส่ง รับประมูล และ  search engine optimization (SEO)  สามารถตอบสนองความต้องการ
            ในกลุ่มอุตสาหกรรม ท้ั้งอุตสาหกรรมขนาดใหญ่ และ อุตสากรรมขนาดเล็กด้วยสินค้าที่หลากหลายพร้อมบริการที่มีคุณภาพรวมถึงทีมงานที่มีประสบการณ์และความเชี่ยวชาญ
            ที่พร้อมตอบสนองความต้องการและบริการลูกค้าอย่างเต็มรูปแบบ </p>
    </div>

    <div class="top-border"></div>
    <div id="prodects" class="row topic">
        <h4>Our Products</h4>
    </div>
    <div id="dataInfo">
		<!-- <h2>Information</h2> -->
		<div class="d-none d-lg-block d-md-block d-sm-block">
			<div id="myCarousel" class="carousel slide" data-ride="carousel">
				<div class="carousel-inner">
					<div class="carousel-item active">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
											height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Lab equipment</h4>
											<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
												Lab Consumables and 
												Apparatus, Lab Glassware 
												and Plasticsware </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Safety equipment</h4>
											<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
												เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
												อุปกรณ์ป้องกันส่วนบุคคล 
												อุปกรณ์สำหรับการทำงาน
												ในพื้นที่อับอากาศ 
												รวมไปถึงสัญญาณเตือนความ
												ปลอดภัยต่างๆ 
												ที่ทำให้ไซต์งานปลอดภัย </p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
										 height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Chemicals</h4>
											<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
												สารเคมีบำบัดน้ำเสีย
												เคมีเกรดวิเคราะห์ สำหรับ
												ห้องปฏิบัติการ
												</p>
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Machines</h4>
											<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Mechanic tools</h4>
											<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a class="carousel-control-prev w-auto" href="#myCarousel" data-slide="prev">
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="sr-only">Previous</span>
				</a>
				<a class="carousel-control-next w-auto" href="#myCarousel" data-slide="next">
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="sr-only">Next</span>
				</a>
			</div>
		</div>
		<div class="d-block d-lg-none d-md-none d-sm-none">
			<div class="row justify-content-center">
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machines.png" width="100"
							height="100" alt="Card image 1">
						<div class="card-body">
							<h4 class="card-title">Machines</h4>
							<p class="card-text">นำเข้าและจัดจำหน่ายเครื่องจักร สำหรับอุตสหกรรมขนาดใหญ่ และอุตสหกรรมขนาดเล็ก</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/machine_tools.png" width="100"
							height="100" alt="Card image 2">
						<div class="card-body">
							<h4 class="card-title">Mechanic tools</h4>
							<p class="card-text">นำเข้าและจัดจำหน่าย  เครื่องมือช่าง อุปกรณ์ก่อสร้าง ทั้ง งานหนัก  และงานเบา ทุกประเภท ตามมาตราฐาน</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/safety_equipment.png" width="100"
							height="100" alt="Card image 3">
						<div class="card-body">
							<h4 class="card-title">Safety equipment</h4>
							<p class="card-text">อุปกรณ์ป้องกันอันตรายจาก
								เครื่องจักร อุปกรณ์ทำงานบนที่สูง 
								อุปกรณ์ป้องกันส่วนบุคคล 
								อุปกรณ์สำหรับการทำงาน
								ในพื้นที่อับอากาศ 
								รวมไปถึงสัญญาณเตือนความ
								ปลอดภัยต่างๆ 
								ที่ทำให้ไซต์งานปลอดภัย </p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/lab_equipment.png" width="100"
							height="100" alt="Card image 4">
						<div class="card-body">
							<h4 class="card-title">Lab equipment</h4>
							<p class="card-text">เครื่องมือและอุปกรณ์วิทยาศาสตร์
								Lab Consumables and 
								Apparatus, Lab Glassware 
								and Plasticsware </p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/products/chemicals.png" width="100"
							height="100" alt="Card image 5">
						<div class="card-body">
							<h4 class="card-title">Chemicals</h4>
							<p class="card-text">จำหน่ายเคมีเกรดอุตสากรรม 
								สารเคมีบำบัดน้ำเสีย
								เคมีเกรดวิเคราะห์ สำหรับ
								ห้องปฏิบัติการ
								</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

    <div class="top-border"></div>
    <div id="services" class="row topic">
        <h4>Services</h4>
    </div>
    <div id="carousel-services">
		<!-- <h2>Information</h2> -->
		<div class="d-none d-lg-block d-md-block d-sm-block">
			<div id="myCarouselService" class="carousel slide" data-ride="carousel">
				<div class="carousel-inner">
					<div class="carousel-item active">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div><div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div><div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div><div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
											height="100" alt="Card image 5">
										<div class="card-body">
											<h4 class="card-title">Web design</h4>
											<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="carousel-item">
						<div class="d-none d-lg-block">
							<div class="row justify-content-center">
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div><div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-2">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
										 height="100" alt="Card image 4">
										<div class="card-body">
											<h4 class="card-title">Construction and design</h4>
											<p class="card-text">บริการ รับออกแบบ รับเหมา 
												ก่อสร้าง ต่อเติมทั้งใน อาคาร
												และนอกอาคาร รวมไปถึงการ
												ปรับปรุงพื้นที่ต่างๆภายใน พื้น
												ที่โรงงาน ยินดีรับผลิตอุปกรณ์
												ตามสเป็คที่ลูกค้ากำหนด 
												(Made to order)  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-md-block d-lg-none">
							<div class="row justify-content-center">
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-md-3">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
										 height="100" alt="Card image 3">
										<div class="card-body">
											<h4 class="card-title">Auction</h4>
											<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
												อุปกรณ์ เครื่องจักร ที่ต้องการ
												จัดจำหน่ายทิ้ง  
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="d-none d-sm-block d-md-none">
							<div class="row justify-content-center">
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
											height="100" alt="Card image 6">
										<div class="card-body">
											<h4 class="card-title">Logistics</h4>
											<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
												และส่งออก เรายังมีบริการด้าน
												การเคลียสินค้าแบบ เร่งด่วน 
												และดำเนินพิธีการกรมศุล แบบ 
												One stop servcies  
												</p>
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
										 height="100" alt="Card image 1">
										<div class="card-body">
											<h4 class="card-title">Cleaning / repaint</h4>
											<p class="card-text">บริการ งานทำความสะอาด 
												อุปกรณ์เครื่องจักรในโรงงาน
												บริการ ทำความสะอาดพื้นที่ใน
												โรงงานอุตสหกรรม 
												งานทาสีอาคาร ทำสีพ้ืน Epoxy 
												และ ทาสีโครงสร้างอาคารใหม่   
												
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div class="card">
										<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
										 height="100" alt="Card image 2">
										<div class="card-body">
											<h4 class="card-title">Machine moving and installation</h4>
											<p class="card-text">บริการ การติดตั้งและขนย้าย
												เครื่องจักรพร้อมเครื่องมือ
												ทันสมัยและทีมงานที่เชี่ยวชาญ
												มีประสบการณ์ และปลอดภัย
												</p>
											<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a class="carousel-control-prev w-auto" href="#myCarouselService" role="button" data-slide="prev">
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="sr-only">Previous</span>
				</a>
				<a class="carousel-control-next w-auto" href="#myCarouselService" role="button" data-slide="next">
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="sr-only">Next</span>
				</a>
			</div>
		</div>
		<div class="d-block d-lg-none d-md-none d-sm-none">
			<div class="row justify-content-center">
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/cleaning_repaint.png" width="100"
						 height="100" alt="Card image 1">
						<div class="card-body">
							<h4 class="card-title">Cleaning / repaint</h4>
							<p class="card-text">บริการ งานทำความสะอาด 
								อุปกรณ์เครื่องจักรในโรงงาน
								บริการ ทำความสะอาดพื้นที่ใน
								โรงงานอุตสหกรรม 
								งานทาสีอาคาร ทำสีพ้ืน Epoxy 
								และ ทาสีโครงสร้างอาคารใหม่   
								
								</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/machine_moving_and_installation.png" width="100"
						 height="100" alt="Card image 2">
						<div class="card-body">
							<h4 class="card-title">Machine moving and installation</h4>
							<p class="card-text">บริการ การติดตั้งและขนย้าย
								เครื่องจักรพร้อมเครื่องมือ
								ทันสมัยและทีมงานที่เชี่ยวชาญ
								มีประสบการณ์ และปลอดภัย
								</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/auction.png" width="100"
						 height="100" alt="Card image 3">
						<div class="card-body">
							<h4 class="card-title">Auction</h4>
							<p class="card-text">บริการ รับซื้อและ ประมูลวัสดุ
								อุปกรณ์ เครื่องจักร ที่ต้องการ
								จัดจำหน่ายทิ้ง  
								</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/construction_and_design.png" width="100"
						 height="100" alt="Card image 4">
						<div class="card-body">
							<h4 class="card-title">Construction and design</h4>
							<p class="card-text">บริการ รับออกแบบ รับเหมา 
								ก่อสร้าง ต่อเติมทั้งใน อาคาร
								และนอกอาคาร รวมไปถึงการ
								ปรับปรุงพื้นที่ต่างๆภายใน พื้น
								ที่โรงงาน ยินดีรับผลิตอุปกรณ์
								ตามสเป็คที่ลูกค้ากำหนด 
								(Made to order)  
								</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/web_design_seo_support.png" width="100"
							height="100" alt="Card image 5">
						<div class="card-body">
							<h4 class="card-title">Web design</h4>
							<p class="card-text">บริการออกแบบ พัฒนา และให้คำปรึกษาเกี่ยวกับเว็บไซต์และแอปพลิเคชั่นต่างๆตามความต้องการของลูกค้า</p>
							<!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
						</div>
					</div>
				</div>
				<div class="col-md-2">
					<div class="card">
						<img class="card-img-top img-fluid rounded-circle" src="assets/img/services/logistics.png" width="100"
							height="100" alt="Card image 6">
						<div class="card-body">
							<h4 class="card-title">Logistics</h4>
							<p class="card-text">บริการงานด้านขนส่ง ทั้้งนำเข้า
								และส่งออก เรายังมีบริการด้าน
								การเคลียสินค้าแบบ เร่งด่วน 
								และดำเนินพิธีการกรมศุล แบบ 
								One stop servcies  
								</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
<div class="container footer page-footer font-small blue pt-4">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
  
      <!-- Grid row -->
      <div id="contact" class="row ">
  
        <!-- Grid column -->
        <div class="col-md-6 mt-md-0 mt-3">
  
          <!-- Content -->
          <h5 class="poppins">Contact</h5>
          <p class="poppins-extralight">Address :57/1 M.9  Banglane Bangyai Nonthaburi 11140 <br>
            Telephone : +66 (9) 791 991 93  +66 (8) 863 571 263 <br>
            Email : sales@hayes-chem.com </p>
  
        </div>
        <!-- Grid column -->
  
        <hr class="clearfix w-100 d-md-none pb-3">
  
        <!-- Grid column -->
        <div class="col-md-3 mb-md-0 mb-3">
  
          <!-- Links -->
          <h5 class="poppins">Follow</h5>
  
          <ul class="list-unstyled poppins-extralight">
            <li>
              <a href="https://www.google.com/maps/place/Soi+Thong+Pan+19,+Tambon+Bang+Len,+Amphoe+Bang+Yai,+Chang+Wat+Nonthaburi+11140/@13.847089,100.427108,16.32z/data=!4m5!3m4!1s0x30e29072eecbeaed:0x7c9cc3ac41bfc9b1!8m2!3d13.8504816!4d100.4298797">Map</a>
            </li>
            <li>
              <a href="https://line.me/R/ti/p/%40774gqmmw">Line</a>
            </li>
            <li>
              <a href="https://www.facebook.com/Hayes-chemical-industry-101223734794821">Facebook</a>
            </li>
          </ul>
  
        </div>
        <!-- Grid column -->

        
        <!-- Grid column -->
        <div class="col-md-3 mb-md-0 mb-3">

            <div class="row" >
                <div>
                    <img src="assets/img/footer/barcode.png" class="rounded float-left img-fluid" alt="hayes.com">
                </div>
            </div>
            <div class="row">
                <img src="assets/img/footer/logo.png" class="rounded float-left img-fluid" alt="hayes.com">
            </div>
    
          </div>
          <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2020 Copyright:
      <a href="https://hayes-chem.com/"> hayes-chem.com</a>
    </div>
    <!-- Copyright -->
  
  </div>
  <!-- Footer -->
</div>