<!-- <app-header></app-header> -->
<div class="jumbotron body container" style="background-color: #fff; height: calc(95vh);">
	<div>
	<h3>Events</h3>
	</div>
    <div class="row justify-content-center">
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/1.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/2.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/3.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/4.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/5.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/6.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/7.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/8.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/9.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/10.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/11.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/12.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/13.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/14.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/15.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/16.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/17.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/18.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/19.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
		<div class="col-md-2">
			<div class="card">
				<img class="card-img-top img-fluid" src="assets/img/events/20.png" width="100"
				 height="100" alt="Card image cap">
			</div>
		</div>
	</div>
	<!-- Footer -->
<div class="container footer page-footer font-small blue pt-4">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
  
      <!-- Grid row -->
      <div id="contact" class="row ">
  
        <!-- Grid column -->
        <div class="col-md-6 mt-md-0 mt-3">
  
          <!-- Content -->
          <h5 class="poppins">Contact</h5>
          <p class="poppins-extralight">Address :57/1 M.9  Banglane Bangyai Nonthaburi 11140 <br>
            Telephone : +66 (9) 791 991 93  +66 (8) 863 571 263 <br>
            Email : sales@hayes-chem.com </p>
  
        </div>
        <!-- Grid column -->
  
        <hr class="clearfix w-100 d-md-none pb-3">
  
        <!-- Grid column -->
        <div class="col-md-3 mb-md-0 mb-3">
  
          <!-- Links -->
          <h5 class="poppins">Follow</h5>
  
          <ul class="list-unstyled poppins-extralight">
            <li>
				<a href="https://www.google.com/maps/place/Soi+Thong+Pan+19,+Tambon+Bang+Len,+Amphoe+Bang+Yai,+Chang+Wat+Nonthaburi+11140/@13.847089,100.427108,16.32z/data=!4m5!3m4!1s0x30e29072eecbeaed:0x7c9cc3ac41bfc9b1!8m2!3d13.8504816!4d100.4298797">Map</a>
			  </li>
			  <li>
				<a href="https://line.me/R/ti/p/%40774gqmmw">Line</a>
			  </li>
			  <li>
				<a href="https://www.facebook.com/Hayes-chemical-industry-101223734794821">Facebook</a>
			  </li>
          </ul>
  
        </div>
        <!-- Grid column -->

        
        <!-- Grid column -->
        <div class="col-md-3 mb-md-0 mb-3">

            <div class="row" >
                <div>
                    <img src="assets/img/footer/barcode.png" class="rounded float-left img-fluid" alt="hayes.com">
                </div>
            </div>
            <div class="row">
                <img src="assets/img/footer/logo.png" class="rounded float-left img-fluid" alt="hayes.com">
            </div>
    
          </div>
          <!-- Grid column -->
  
      </div>
      <!-- Grid row -->
  
    </div>
    <!-- Footer Links -->
  
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2020 Copyright:
      <a href="https://hayes-chem.com/"> hayes-chem.com</a>
    </div>
    <!-- Copyright -->
  
  </div>
  <!-- Footer -->
</div>